import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { FuseConfirmationService } from '@fuse/services/confirmation/confirmation.service';
import { FuseConfirmationDialogComponent } from '@fuse/services/confirmation/dialog/dialog.component';
import { CommonModule } from '@angular/common';
import { DialogEmailComponent } from './dialog-email/dialog-email.component';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogDescriptionComponent } from './dialog-description/dialog-description.component';
import { QuillModule } from 'ngx-quill';
import { DialogServidorComponent } from './dialog-servidor/dialog-servidor.component';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
    declarations: [
        DialogEmailComponent,
        DialogServidorComponent,
        DialogDescriptionComponent,
        FuseConfirmationDialogComponent,
    ],
    imports     : [
        FormsModule,
        CommonModule,
        NgxMaskModule,
        MatIconModule,
        MatInputModule,
        MatButtonModule,
        MatDialogModule,
        ReactiveFormsModule,
        QuillModule.forRoot(),
    ],
    providers   : [
        FuseConfirmationService
    ]
})
export class FuseConfirmationModule
{
    /**
     * Constructor
     */
    constructor(private _fuseConfirmationService: FuseConfirmationService)
    {
    }
}
