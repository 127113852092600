export const environment = {
  envName: '',
  production: false,
  api: "https://cpe-api.geia.vip",
  proxy: "https://integrador-proxy-api.geia.vip",
version:'1.2.1 - 20240723',
  formularioPreenchido: false,
  appUrl: "https://cpe.geia.vip",
  copyrightText: "Development env",
  enableSSO: false,
  ssoAuthUrl: "",
  ssoBtnLabel: ""
};
