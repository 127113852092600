import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ExtraOptions, PreloadAllModules, RouterModule } from "@angular/router";
import { FuseModule } from "@fuse";
import { FuseConfigModule } from "@fuse/services/config";
import { AppComponent } from "app/app.component";
import { appRoutes } from "app/app.routing";
import { appConfig } from "app/core/config/app.config";
import { CoreModule } from "app/core/core.module";
import { LayoutModule } from "app/layout/layout.module";
import { MarkdownModule } from "ngx-markdown";
import { IConfig, NgxMaskModule } from "ngx-mask";
import { AuthInterceptor } from "./core/auth/auth.interceptor";
import { ErrorInterceptor } from "./core/interceptor/error.interceptor";
import { AuthAdminInterceptor } from "./modules/admin/auth/auth-admin.interceptor";

const routerConfig: ExtraOptions = {
  preloadingStrategy: PreloadAllModules,
  scrollPositionRestoration: "enabled",
};
export const options: Partial<null | IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatInputModule,
    FormsModule,
    RouterModule.forRoot(appRoutes, routerConfig),
    NgxMaskModule.forRoot(),

    // Fuse, FuseConfig & FuseMockAPI
    FuseModule,
    FuseConfigModule.forRoot(appConfig),

    // Core module of your application
    CoreModule,

    // Layout module of your application
    LayoutModule,

    // 3rd party modules that require global configuration via forRoot
    MarkdownModule.forRoot({}),
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthAdminInterceptor,
      multi: true,
    },
    { provide: MAT_DATE_LOCALE, useValue: "pt-BR" },
  ],
})
export class AppModule { }
