import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { AlterarSenhaAPIService } from "../api/alterar-senha.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AlertService } from "app/core/alert/alert.service";
import { createPasswordStrengthValidator } from "../passwordStrength.validator";
import { confirmPasswordValidator } from "../confirmPassword.validator";

@Component({
  selector: "alterar-senha",
  templateUrl: "alterar-senha.component.html",
})
export class AlterarSenhaComponent implements OnInit {
  form: FormGroup;
  isLoading = false;

  constructor(
    private _service: AlterarSenhaAPIService,
    private _fb: FormBuilder,
    public dialogRef: MatDialogRef<AlterarSenhaComponent>,
    private _alert: AlertService
  ) {}

  ngOnInit() {
    this.createForm();
  }

  createForm(): void {
    this.form = this._fb.group({
      novaSenha: [
        "",
        [
          Validators.required,
          Validators.minLength(8),
          createPasswordStrengthValidator(),
        ],
      ],
      confirmarSenha: ["", [Validators.required]],
    });

    this.form.controls.confirmarSenha.setValidators(
      confirmPasswordValidator(this.form.controls.novaSenha)
    );
  }

  handleSubmit(): void {
    this.isLoading = true;

    this._service.alterarSenha(this.form.value).subscribe({
      next: (res) => {
        this.isLoading = false;
        this._alert.success("Senha alterada com sucesso!");
        this.fecharDialog();
      },
      error: (err) => {
        this.isLoading = false;
        if (err) this._alert.error(err);
        else this._alert.error("Ocorreu um erro ao alterar a senha");
      },
    });
  }

  fecharDialog(): void {
    this.dialogRef.close();
  }
}
