import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { merge } from "lodash-es";
import { FuseConfirmationDialogComponent } from "@fuse/services/confirmation/dialog/dialog.component";
import { FuseConfirmationConfig } from "@fuse/services/confirmation/confirmation.types";
import { DialogEmailComponent } from "./dialog-email/dialog-email.component";
import { ConfirmationConfig } from "./dialog-email/confirmation-email.types";
import { DialogDescriptionComponent } from "./dialog-description/dialog-description.component";
import { DialogServidorComponent } from "./dialog-servidor/dialog-servidor.component";
import { ConfirmationServidorConfig } from "./dialog-servidor/confirmation-servidor.types";

@Injectable()
export class FuseConfirmationService {
  private _defaultConfig: FuseConfirmationConfig = {
    title: "Confirmar ação",
    message: "Tem certeza de que deseja confirmar esta ação",
    icon: {
      show: true,
      name: "heroicons_outline:exclamation",
      color: "warn",
    },
    actions: {
      confirm: {
        show: true,
        label: "Confirmar",
        color: "warn",
      },
      cancel: {
        show: true,
        label: "Cancelar",
      },
    },
    dismissible: false,
  };

  /**
   * Constructor
   */
  constructor(private _matDialog: MatDialog) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  open(
    config: FuseConfirmationConfig = {}
  ): MatDialogRef<FuseConfirmationDialogComponent> {
    // Merge the user config with the default config
    const userConfig = merge({}, this._defaultConfig, config);

    // Open the dialog
    return this._matDialog.open(FuseConfirmationDialogComponent, {
      autoFocus: false,
      disableClose: !userConfig.dismissible,
      data: userConfig,
      panelClass: "fuse-confirmation-dialog-panel",
    });
  }

  openEmail(
    config: ConfirmationConfig = {}
  ): MatDialogRef<DialogEmailComponent> {
    // Merge the user config with the default config
    const userConfig = merge({}, this._defaultConfig, config);

    // Open the dialog
    return this._matDialog.open(DialogEmailComponent, {
      autoFocus: false,
      disableClose: !userConfig.dismissible,
      data: userConfig,
      panelClass: "fuse-confirmation-dialog-panel",
    });
  }

  openDescricao(
    config: ConfirmationConfig = {}
  ): MatDialogRef<DialogDescriptionComponent> {
    // Merge the user config with the default config
    const userConfig = merge({}, this._defaultConfig, config);

    // Open the dialog
    return this._matDialog.open(DialogDescriptionComponent, {
      autoFocus: false,
      disableClose: !userConfig.dismissible,
      data: userConfig,
      panelClass: "fuse-confirmation-dialog-panel",
    });
  }

  openServidor(
    config: ConfirmationServidorConfig = {}
  ): MatDialogRef<DialogServidorComponent> {
    // Merge the user config with the default config
    const userConfig = merge({}, this._defaultConfig, config);

    // Open the dialog
    return this._matDialog.open(DialogServidorComponent, {
      autoFocus: false,
      disableClose: !userConfig.dismissible,
      data: userConfig,
      panelClass: "fuse-confirmation-dialog-panel",
    });
  }
}
