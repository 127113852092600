import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { FuseAlertAppearance, FuseAlertType } from '@fuse/components/alert';
import { AlertService } from './alert.service';

export class Alert {
  message: '';
  appearance: FuseAlertAppearance = 'soft';
  dismissed: boolean = false;
  dismissible: boolean = false;
  name: string;
  showIcon: boolean = true;
  type: FuseAlertType = 'primary';

  constructor(alert: Alert) {
    this.message = alert.message;
    this.appearance = alert.appearance;
    this.dismissed = alert.dismissed;
    this.dismissible = alert.dismissible;
    this.name = alert.name;
    this.showIcon = alert.showIcon;
    this.type = alert.type;
  }
}

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
})
export class AlertComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: Alert,
    private _alertService: AlertService,
  ) {}

  handleClose(): void {
    this._alertService.dismiss();
  }
}
