import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QuillModules } from 'ngx-quill';
import { ConfirmationDescriptionConfig } from './confirmation-description.types';
@Component({
  selector: 'app-dialog-description',
  templateUrl: './dialog-description.component.html',
  styles: [
    `
        .fuse-confirmation-dialog-panel {
            @screen md {
                @apply w-160;
            }

            .mat-dialog-container {
                padding: 0 !important;
            }
        }
    `
  ],
  encapsulation: ViewEncapsulation.None
})
export class DialogDescriptionComponent {

  quillModules: QuillModules = {
    toolbar: [
      ["bold", "italic", "underline"],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ align: [] }, { list: "ordered" }, { list: "bullet" }],
      ["blockquote", "code-block"],
      ["link"],
      ["clean"],
    ],
  };

  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmationDescriptionConfig) {
  }


  
}
