import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDividerModule } from "@angular/material/divider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatTooltipModule } from "@angular/material/tooltip";
import { FuseFindByKeyPipeModule } from "@fuse/pipes/find-by-key";
import { IConfig, NgxMaskModule } from "ngx-mask";
import { AppFooter } from "./components/app-footer/app-footer.component";
import { FileItemComponent } from "./components/file-item/file-item.component";
import { FiltroAvancadoComponent } from "./components/filtor-avancado/filtro-avancado.component";
import { OverlayOptionComponent } from "./components/overlay-option/overlay-option.component";
import { UfsAutoCompleteComponent } from "./components/ufs-autocomplete/ufs-autocomplete.component";
import { UploadFileComponent } from "./components/upload-file/upload-file.component";
import { PermissionOnlyDirective } from "./directives/permissions/permission-only.directive";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";

export const options: Partial<null | IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatDividerModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    NgxMaskModule,
    MatRadioModule,
    MatAutocompleteModule,
    FuseFindByKeyPipeModule,
    MatDatepickerModule,
    MatNativeDateModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    NgxMaskModule,
    MatDividerModule,
    FileItemComponent,
    UploadFileComponent,
    OverlayOptionComponent,
    UfsAutoCompleteComponent,
    PermissionOnlyDirective,
    FiltroAvancadoComponent,
    AppFooter,
    MatDatepickerModule,
    MatNativeDateModule,
  ],
  declarations: [
    FileItemComponent,
    UploadFileComponent,
    UfsAutoCompleteComponent,
    PermissionOnlyDirective,
    AppFooter,
    OverlayOptionComponent,
    FiltroAvancadoComponent,
  ],
})
export class SharedModule {}
